
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$jfs-agent-portal-primary: mat.define-palette(mat.$indigo-palette);
$jfs-agent-portal-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$jfs-agent-portal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$jfs-agent-portal-theme: mat.define-light-theme((
  color: (
    primary: $jfs-agent-portal-primary,
    accent: $jfs-agent-portal-accent,
    warn: $jfs-agent-portal-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($jfs-agent-portal-theme);


@font-face {
  font-family: "inter";
  src: url("./assets/fonts/inter/Inter-Regular.woff2") format('woff2'),
       url("./assets/fonts/inter/Inter-Regular.woff") format('woff');
}
@font-face {
  font-family: "inter";
  src: url("./assets/fonts/inter/Inter-SemiBold.woff2") format('woff2'),
       url("./assets/fonts/inter/Inter-SemiBold.woff") format('woff');
  font-weight: 700;
}
@font-face {
  font-family: "inter";
  src: url("./assets/fonts/inter/Inter-Thin.woff2") format('woff2'),
       url("./assets/fonts/inter/Inter-Thin.woff") format('woff');
  font-weight: 300;
}
@font-face {
  font-family: "inter";
  src: url("./assets/fonts/inter/Inter-ThinItalic.woff2") format('woff2'),
       url("./assets/fonts/inter/Inter-ThinItalic.woff") format('woff');
  font-weight: 300;
  font-style: italic;
}



/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './style/material.scss';





html, body { height: 100%; }
body { margin: 0}
.video-js{
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.video-js .vjs-control-bar{
  height:55px;
  border-radius: 10px;
  bottom:20px;
  left:20px;
  right:20px;
  width: calc(100% - 40px);
  background-color: rgba(#32252c,.8);
}