.reset{
    background: none;
    max-width:100vw!important;
    .mat-mdc-dialog-container .mdc-dialog__surface,.mat-mdc-dialog-container {
        background:none;
    }
    .mat-mdc-dialog-container .mdc-dialog__surface{
        box-shadow: none;
        overflow:visible;
    }
}